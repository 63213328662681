<template>
    <b-card>
      <div slot="header">
        <strong>Lista de regras</strong>
      </div>
      <div>
        <router-link :to="{ name: 'Criar Regra', params: { id: this.$route.params.id } }" class="btn btn-primary">Criar Regra</router-link></p>
      </div>
      <br>

      <table class="table" v-if="regras.length > 0">
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Ativo</th>
            <th>Observação</th>
            <th style="width: 140px;">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="regraItem in regras">
            <td> {{ regraItem.descricao }} </td>
            <td>
              <span v-if="regraItem.ativo" class="badge badge-pill badge-success">Ativo</span>
              <span v-if="!regraItem.ativo" class="badge badge-pill badge-danger">Inativo</span>
            </td>
            <td> {{ regraItem.observacao }} </td>
            <td>
              <b-btn :to="{ name: 'Editar Regra', params: { id: regraItem.id } }" variant="success" size="sm">Editar</b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
</template>

<script>
export default {
  data () {
    return {
      regras: []
    }
  },
  created: function () {
    this.verificaSessao()

    this.regras = []
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.regraListar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    regraListar: function () {
      this.listar('gp_Regra', 'role_descricao').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.regras.push({
              descricao: response[i].role_descricao,
              observacao: response[i].role_observacao,
              ativo: response[i].role_ativo,
              id: response[i].role_ID
            })
          }
        }
      )
    }
  }
}
</script>

<style>

</style>
